import React, { useContext, useState } from 'react';
import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { CircleX } from 'tabler-icons-react';
import { showAppNotifcation } from '../../../../utility/NotificationConfigs';
import { OrderPositionContext } from '../../../../context/ContextWrapper';
import { AbstractContextType } from '../../../../context/AbstractProvider';
import { useTranslation } from 'react-i18next';
import IOrderPosition from '../../../../models/IOrder';
import orderPositionService from '../../../../services/OrderPositionService';
import IInquiry, { getPrefixedInquiryId } from '../../../../models/IInquiry';
import { ORDER_STATUS_ENUM } from '../../../../api_enums/ORDER_STATUS_ENUM';

export function OrderPositionDeleteDialog(props: {
  orderPosition: IOrderPosition;
}) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const ctx = useContext(
    OrderPositionContext,
  ) as AbstractContextType<IOrderPosition>;

  const checkPermission = () => {
    return (
      props.orderPosition.remain_loading != 0 &&
      (props.orderPosition.status === ORDER_STATUS_ENUM.PICKING ||
        props.orderPosition.status === ORDER_STATUS_ENUM.NOT_DELIVERED)
    );
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('OrderPositionDeleteDialog.Title')}
      >
        <>
          <Stack>
            <Text>
              {t('OrderPositionDeleteDialog.RejectConfirm', {
                name: getPrefixedInquiryId(
                  (props.orderPosition.inquired_through as IInquiry).id,
                ),
              })}
            </Text>
            <Group>
              <Button
                color="red"
                onClick={() => {
                  orderPositionService
                    .remove(props.orderPosition.id)
                    .then(() => {
                      ctx.remove(props.orderPosition.id);
                      showAppNotifcation(
                        true,
                        t('OrderPositionDeleteDialog.ConfirmedRejected'),
                      );
                    })
                    .catch(() => {
                      showAppNotifcation(
                        false,
                        t('OrderPositionDeleteDialog.CannotReject'),
                      );
                    })
                    .finally(() => setOpened(false));
                }}
              >
                {t('main.Decline')}
              </Button>
              <Button color="gray" onClick={() => setOpened(false)}>
                {t('main.Cancel')}
              </Button>
            </Group>
          </Stack>
        </>
      </Modal>

      <ActionIcon
        sx={{ '&[data-disabled]': { opacity: 0.4 } }}
        disabled={!checkPermission()}
        color="red"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Decline')}
      >
        <CircleX />
      </ActionIcon>
    </>
  );
}
