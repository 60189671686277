import React, { useContext, useState } from 'react';
import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { Trash } from 'tabler-icons-react';
import IVehicle from '../../models/IVehicle';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import VehicleService from '../../services/VehicleService';
import { AbstractContextType } from '../../context/AbstractProvider';
import { VehicleContext } from '../../context/ContextWrapper';
import { useTranslation } from 'react-i18next';

export function VehicleDeleteDialog(props: { vehicle: IVehicle }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const ctx = useContext(VehicleContext) as AbstractContextType<IVehicle>;

  const onDelete = () => {
    VehicleService.remove(props.vehicle.id)
      .then(() => {
        ctx.remove(props.vehicle.id);
        showAppNotifcation(
          true,
          t('VehicleDeleteDialog.VehicleDeletedSuccessfully'),
        );
      })
      .catch((err) => {
        console.log(err);
        showAppNotifcation(
          false,
          t('VehicleDeleteDialog.VehicleCouldNotDelete'),
        );
      })
      .finally(() => setOpened(false));
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('VehicleDeleteDialog.DeleteVehicle')}
      >
        <Stack>
          <Text>
            {t('VehicleDeleteDialog.DeleteConfirm', {
              licensePlate: props.vehicle.license_plate,
            })}
          </Text>
          <Group>
            <Button color="red" onClick={onDelete}>
              {t('main.Delete')}
            </Button>
            <Button color="gray" onClick={() => setOpened(false)}>
              {t('main.Cancel')}
            </Button>
          </Group>
        </Stack>
      </Modal>

      <ActionIcon
        sx={{ '&[data-disabled]': { opacity: 0.4 } }}
        color="red"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Delete')}
      >
        <Trash />
      </ActionIcon>
    </>
  );
}
