import React, { useContext, useState } from 'react';
import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { Trash } from 'tabler-icons-react';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import { PackageUnitContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import IPackageUnit from '../../models/IPackageUnit';
import PackageUnitService from '../../services/PackageUnitService';
import { useTranslation } from 'react-i18next';

export function PackageUnitDeleteDialog(props: { packageUnit: IPackageUnit }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const ctx = useContext(
    PackageUnitContext,
  ) as AbstractContextType<IPackageUnit>;

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('PackageUnitDeleteDialog.Title')}
      >
        <>
          <Stack>
            <Text>
              {t('PackageUnitDeleteDialog.DeleteConfirm', {
                name: props.packageUnit.name,
              })}
            </Text>
            <Group>
              <Button
                color="red"
                onClick={() => {
                  PackageUnitService.remove(props.packageUnit.id)
                    .then(() => {
                      ctx.remove(props.packageUnit.id);
                      showAppNotifcation(
                        true,
                        t(
                          'PackageUnitDeleteDialog.PackageUnitDeletedSuccessfully',
                        ),
                      );
                    })
                    .catch(() => {
                      showAppNotifcation(
                        false,
                        t('PackageUnitDeleteDialog.PackageUnitCannotDelete'),
                      );
                    })
                    .finally(() => setOpened(false));
                }}
              >
                {t('main.Delete')}
              </Button>
              <Button color="gray" onClick={() => setOpened(false)}>
                {t('main.Cancel')}
              </Button>
            </Group>
          </Stack>
        </>
      </Modal>

      <ActionIcon
        sx={{ '&[data-disabled]': { opacity: 0.4 } }}
        color="red"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Delete')}
      >
        <Trash />
      </ActionIcon>
    </>
  );
}
