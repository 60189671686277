import {
  createStyles,
  Group,
  Paper,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';

import { LocationEditDialog } from './LocationEditDialog';
import { LocationDeleteDialog } from './LocationDeleteDialog';
import { LocationCreateDialog } from './LocationCreateDialog';
import React, { useContext, useState } from 'react';
import ILocation from '../../models/ILocation';
import ISection from '../../models/ISection';
import { LocationContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import { useTranslation } from 'react-i18next';
import cx from 'clsx';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    transition: 'box-shadow 150ms ease',
    after: {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: 'rem(1px) solid',
    },
  },
  scrolled: {
    boxShadow: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05)',
  },
}));

function createRows(elements: ILocation[]) {
  return elements.map((element) => {
    return (
      <tr key={element.id}>
        {/*<td>{getPrefixedLocationId(element.id)}</td>*/}
        <td> {element.name} </td>
        <td> {element.street_name} </td>
        <td> {element.house_number} </td>
        <td> {element.postal_code} </td>
        <td> {element.city} </td>
        <td> {(element.belongs_to as ISection)?.name} </td>
        <td>
          <Group spacing="xs">
            <LocationEditDialog location={element} />
            <LocationDeleteDialog location={element} />
          </Group>
        </td>
      </tr>
    );
  });
}

function LocationTable() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const ctx = useContext(LocationContext) as AbstractContextType<ILocation>;
  const [scrolled, setScrolled] = useState(false);

  return (
    <Paper withBorder radius="md" p="xs">
      <Stack>
        <Title order={2}>Lieferorte</Title>
        <Group>
          <LocationCreateDialog />
        </Group>
        <ScrollArea
          h={'85vh'}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table striped verticalSpacing="xs" fontSize="md">
            <thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <tr>
                {/*<th>{t('main.LocationNo')}</th>*/}
                <th>{t('main.Name')}</th>
                <th>{t('main.Street')}</th>
                <th>{t('main.HouseNumber')}</th>
                <th>{t('main.PostalCode')}</th>
                <th>{t('main.City')}</th>
                <th>{t('LocationTable.OperationsSection')}</th>
                <th>{t('main.Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {createRows(ctx.entities)}

              {ctx.loading &&
                [1, 2].map((id) => {
                  return (
                    <tr key={id}>
                      <td>
                        <Skeleton height={12} mt={6} width="50%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="70%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="70%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="20%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="60%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="70%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="20%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="60%" radius="xl" />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ScrollArea>
        {!ctx.loading && ctx.entities.length === 0 && (
          <Text align="center">{t('LocationTable.NoDeliveryLocation')}</Text>
        )}
      </Stack>
    </Paper>
  );
}

export default LocationTable;
