import React, { useContext, useState } from 'react';
import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { Trash } from 'tabler-icons-react';
import { showAppNotifcation } from '../../../utility/NotificationConfigs';
import { InquiryContext } from '../../../context/ContextWrapper';
import { AbstractContextType } from '../../../context/AbstractProvider';
import IInquiry, { getPrefixedInquiryId } from '../../../models/IInquiry';
import InquiryService from '../../../services/InquiryService';
import { useTranslation } from 'react-i18next';
import ISection from '../../../models/ISection';
import IUser from '../../../models/IUser';
import { INQUIRY_STATUS_ENUM } from '../../../api_enums/INQUIRY_STATUS_ENUM';
import { AuthContext, AuthContextType } from '../../../context/AuthProvider';

export function InquiryDeleteDialog(props: { inquiry: IInquiry }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const ctx = useContext(InquiryContext) as AbstractContextType<IInquiry>;
  const { userInfo } = useContext(AuthContext) as AuthContextType;

  const checkPermission = () => {
    console.log(props.inquiry.id, userInfo.section_supervisor_id);
    return (
      (((props.inquiry?.inquired_by as IUser)?.id === userInfo?.user_id &&
        (props.inquiry?.is_waiting_at as ISection)?.id ===
          userInfo.section_supervisor_id) ||
        (props.inquiry?.is_waiting_at as ISection)?.id ===
          userInfo?.section_id ||
        !userInfo.section_supervisor_id) &&
      ((props.inquiry?.status as INQUIRY_STATUS_ENUM) ===
        INQUIRY_STATUS_ENUM.INQUIRED ||
        (props.inquiry?.status as INQUIRY_STATUS_ENUM) ===
          INQUIRY_STATUS_ENUM.IN_APPROVAL ||
        (props.inquiry?.status as INQUIRY_STATUS_ENUM) ===
          INQUIRY_STATUS_ENUM.APPROVED)
    );
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('InquiryDeleteDialog.Title')}
      >
        <Stack>
          <Text>
            {t('main.Question')} {getPrefixedInquiryId(props.inquiry.id)}{' '}
            {t('main.DeleteConfirm')}
          </Text>
          <Group>
            <Button
              color="red"
              onClick={() => {
                InquiryService.remove(props.inquiry.id)
                  .then(() => {
                    ctx.remove(props.inquiry.id);
                    showAppNotifcation(
                      true,
                      t('InquiryDeleteDialog.ConfirmedDelete'),
                    );
                  })
                  .catch(() => {
                    showAppNotifcation(
                      false,
                      t('InquiryDeleteDialog.CannotDelete'),
                    );
                  })
                  .finally(() => setOpened(false));
              }}
            >
              {t('main.Delete')}
            </Button>
            <Button color="gray" onClick={() => setOpened(false)}>
              {t('main.Cancel')}
            </Button>
          </Group>
        </Stack>
      </Modal>
      <ActionIcon
        sx={{ '&[data-disabled]': { opacity: 0.4 } }}
        disabled={!checkPermission()}
        color="red"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Remove')}
      >
        <Trash />
      </ActionIcon>
    </>
  );
}
