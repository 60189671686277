import React, { useContext, useState } from 'react';
import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { Trash } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';
import IVehicleType from '../../../models/IVehicleType';
import { VehicleTypeContext } from '../../../context/ContextWrapper';
import VehicleTypeService from '../../../services/VehicleTypeService';
import { AbstractContextType } from '../../../context/AbstractProvider';
import { showAppNotifcation } from '../../../utility/NotificationConfigs';

export function VehicleTypeDeleteDialog(props: { vehicleType: IVehicleType }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const ctx = useContext(
    VehicleTypeContext,
  ) as AbstractContextType<IVehicleType>;

  const onDelete = () => {
    VehicleTypeService.remove(props.vehicleType.id)
      .then(() => {
        ctx.remove(props.vehicleType.id);
        showAppNotifcation(
          true,
          t('VehicleTypeDeleteDialog.VehicleDeletedSuccessfully'),
        );
      })
      .catch((err) => {
        console.log(err);
        showAppNotifcation(
          false,
          t('VehicleTypeDeleteDialog.VehicleCouldNotDelete'),
        );
      })
      .finally(() => setOpened(false));
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('VehicleTypeDeleteDialog.DeleteVehicle')}
      >
        <Stack>
          <Text>
            {t('VehicleTypeDeleteDialog.DeleteConfirm', {
              abbreviation: props.vehicleType.abbreviation,
            })}
          </Text>
          <Group>
            <Button color="red" onClick={onDelete}>
              {t('main.Delete')}
            </Button>
            <Button color="gray" onClick={() => setOpened(false)}>
              {t('main.Cancel')}
            </Button>
          </Group>
        </Stack>
      </Modal>

      <ActionIcon
        sx={{ '&[data-disabled]': { opacity: 0.4 } }}
        color="red"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Delete')}
      >
        <Trash />
      </ActionIcon>
    </>
  );
}
