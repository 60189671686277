import {
  createStyles,
  Group,
  Paper,
  ScrollArea,
  SimpleGrid,
  Skeleton,
  Space,
  Stack,
  Switch,
  Table,
  Text,
  Title,
} from '@mantine/core';

import React, { useContext, useState } from 'react';
import { DeliveryContext } from '../../../../context/ContextWrapper';
import { AbstractContextType } from '../../../../context/AbstractProvider';
import {
  AuthContext,
  AuthContextType,
  IUserInfo,
} from '../../../../context/AuthProvider';
import { useTranslation } from 'react-i18next';
import IDelivery, { getPrefixedDeliveryId } from '../../../../models/IDelivery';
import IVehicle from '../../../../models/IVehicle';
import { DeliveryCreateDialog } from './DeliveryCreateDialog';
import { DeliveryStatusElement } from '../../DeliveryStatusElement';
import { DeliveryEditDialog } from './DeliveryEditDialog';
import { DeliveryPrintDialog } from './DeliveryPrintDialog';
import { useInputState } from '@mantine/hooks';
import UserService from '../../../../services/UserService';
import { showAppNotifcation } from '../../../../utility/NotificationConfigs';
import IPackageUnit from '../../../../models/IPackageUnit';
import IVehicleType from '../../../../models/IVehicleType';
import { DELIVERY_STATUS_ENUM } from '../../../../api_enums/DELIVERY_STATUS_ENUM';
import { sortDeliveriesByStatusId } from '../../../../utility/sorter';
import { ROLE_ENUM } from '../../../../api_enums/ROLE_ENUM';
import ISection from '../../../../models/ISection';
import cx from 'clsx';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    transition: 'box-shadow 150ms ease',
    after: {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: 'rem(1px) solid',
    },
  },
  scrolled: {
    boxShadow: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05)',
  },
}));

function createRows(
  elements: IDelivery[],
  userInfo: IUserInfo,
  showAllEntries: boolean,
) {
  const checkIsAdmin = () => {
    return (
      userInfo.section_roles.includes(ROLE_ENUM.MANAGER) ||
      userInfo.section_roles.includes(ROLE_ENUM.ADMINISTRATOR)
    );
  };

  return elements
    .filter((element) => {
      return (
        (showAllEntries ||
          (element as IDelivery)?.status === DELIVERY_STATUS_ENUM.PICKING) &&
        (checkIsAdmin() ||
          (element.assigned_to as ISection).id === userInfo.section_id)
      );
    })
    .sort((a, b) => sortDeliveriesByStatusId(a, b))
    .map((element: IDelivery) => {
      return (
        <tr key={element?.id}>
          <td>{getPrefixedDeliveryId(element?.id)}</td>
          <td>
            <DeliveryStatusElement status={element?.status} />
          </td>
          <td>
            {((element.delivered_through as IVehicle)?.type_of as IVehicleType)
              ?.name + ' '}
            {(element.delivered_through as IVehicle)?.radio_call_name}
            {!(element.delivered_through as IVehicle)?.license_plate
              ? ''
              : '(' +
                (element.delivered_through as IVehicle)?.license_plate +
                ')'}
          </td>
          <td>
            {(element.delivered_through as IVehicle)?.max_cargo}
            {(
              (element.delivered_through as IVehicle)
                ?.package_unit as IPackageUnit
            )?.name +
              ',' +
              (element.delivered_through as IVehicle)?.max_weight}
            {(element.delivered_through as IVehicle)?.weight_unit}
          </td>
          <td>{element?.loaded_amount ? element?.loaded_amount : 0}</td>
          <td>
            <Group spacing="xs">
              <DeliveryEditDialog delivery={element} />
              <DeliveryPrintDialog delivery={element} />
            </Group>
          </td>
        </tr>
      );
    });
}

function DeliveryTable() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const ctxDelivery = useContext(
    DeliveryContext,
  ) as AbstractContextType<IDelivery>;
  const { userInfo } = useContext(AuthContext) as AuthContextType;
  const { getOptions, setOptions } = useContext(AuthContext) as AuthContextType;
  const options = getOptions();
  const [showAllDeliveriesEntries, setShowAllDeliveriesEntries] = useInputState(
    options.show_delivery_indicators,
  );
  const [scrolled, setScrolled] = useState(false);

  const updateOptions = (show_delivery_indicators: boolean) => {
    const opt = {
      ...options,
      show_delivery_indicators: show_delivery_indicators,
    };
    UserService.saveOptions({ options: opt })
      .then(() => {
        setOptions(opt);
      })
      .catch(() => showAppNotifcation(false, t('main.OptionCannotChange')));
  };

  return (
    <Paper withBorder radius="md" p="xs">
      <Stack>
        <Title order={2}>{t('ShipmentTable.Title')}</Title>
        <SimpleGrid cols={2}>
          <Group>
            <DeliveryCreateDialog />
          </Group>
          <Group position="right">
            <Switch
              labelPosition="left"
              label={t('ShipmentTable.ShowAllDeliveries')}
              color="green"
              checked={showAllDeliveriesEntries}
              onChange={(event) => {
                setShowAllDeliveriesEntries(event.currentTarget.checked);
                updateOptions(event.currentTarget.checked);
              }}
            />
            <Space w="xs" />
          </Group>
        </SimpleGrid>
        <ScrollArea
          style={{ width: 'auto' }}
          h={'85vh'}
          type="always"
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table striped verticalSpacing="xs" fontSize="md">
            <thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <tr>
                <th>{t('main.DeliveryNo')}</th>
                <th>{t('main.Status')}</th>
                <th>{t('main.Vehicle')}</th>
                <th>{t('main.Capacity')}</th>
                <th>{t('DeliveryTable.loadedAmount')}</th>
                <th>{t('main.Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {createRows(
                ctxDelivery.entities,
                userInfo,
                showAllDeliveriesEntries,
              )}
              {ctxDelivery.loading &&
                /* TODO ajust skeleton */
                [1, 2].map((id) => {
                  return (
                    <tr key={id}>
                      <td>
                        <Skeleton height={12} mt={6} width="50%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="70%" radius="xl" />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ScrollArea>
        {!ctxDelivery.loading && ctxDelivery.entities.length == 0 && (
          <Text align="center">{t('DeliveryTable.NoDeliveryAvailable')}</Text>
        )}
      </Stack>
    </Paper>
  );
}

export default DeliveryTable;
