import React, { useState } from 'react';
import { ActionIcon, Modal } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';
import ILocation from '../../models/ILocation';
import { LocationForm } from './LocationForm';
import { useTranslation } from 'react-i18next';

export function LocationEditDialog(props: { location: ILocation }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        size="lg"
        title={t('LocationEditDialog.Title')}
      >
        <LocationForm
          create={false}
          location={props.location}
          onFinish={() => setOpened(false)}
        />
      </Modal>
      <ActionIcon
        sx={{ '&[data-disabled]': { opacity: 0.4 } }}
        color="orange"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Edit')}
      >
        <Pencil />
      </ActionIcon>
    </>
  );
}
